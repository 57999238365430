import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    .raw-html h2,
    .perks h3 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 140%;
    }

    .raw-html h2 {
      margin-top: 50px;
    }
  }
`;

export { Root };
