import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import RawHTML from "components/Common/RawHTML";

const Introduction = ({ className }) => {
  const { whitepaper } = useSiteData().pages;

  return (
    <Root className={`body-width ${className || ""}`}>
      <RawHTML html={whitepaper.hodlExplained} />
      <section className="perks">
        <h3>{whitepaper.perksTitle}</h3>
        <RawHTML html={whitepaper.perks} />
      </section>
    </Root>
  );
};

export default Introduction;
