import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  .page-header {
    text-align: left;
  }

  .contract-feature {
    margin-top: 40px;
  }

  .contract-feature h3 {
    margin: 25px 0 20px;
    font-size: 1rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 15px;
  }

  section {
    margin-bottom: 27px;
    padding: 27px 30px 21px;
    background: rgb(17, 19, 49);
    border-radius: 10px;
  }

  section div {
    color: ${vars.colors.grey};
  }

  section h3 {
    color: white;
  }

  section span {
    color: ${vars.colors.blue};
  }

  section.perks h3 {
    margin-bottom: 15px;
  }

  section.perks ul {
    margin: 25px 0;
    padding-left: 18px;
    color: ${vars.colors.blue};
  }

  section.perks ul li {
    list-style: disc;
  }

  section.taxes > div {
    margin-bottom: 22px;
  }

  section.taxes > div:last-child {
    margin-bottom: 0;
  }

  section a.button {
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 15px 35px;
  }

  ${vars.desktop.mediaQuery} {
    .page-header p {
      padding: 0 650px 0 0;
    }

    .wrapper {
      padding-top: 85px;
      overflow: auto;
    }

    .wrapper h2 {
      padding-bottom: 20px;
      font-size: 1.5rem;
    }

    section a.button {
      margin-right: 15px;
      padding: 16px 28px;
    }

    section.perks {
      width: 725px;
      margin: 60px 0;
      line-height: 170%;
    }

    .tokenomics {
      width: 450px;
      padding-left: 125px;
      padding-bottom: 125px;
      float: right;
    }

    .raw-html p {
      color: ${vars.colors.grey};
    }

    .join-the-communities {
      margin-top: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .wrapper h2 {
      padding-bottom: 10px;
    }

    section.perks {
      margin: 45px 0;
    }

    section a.button {
      margin-right: 15px;
      padding: 14px 26px;
    }

    .tokenomics {
      margin-bottom: 52px;
      font-size: 0.775rem;
      line-height: 260%;
    }

    .tokenomics h2 {
      padding-bottom: 15px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 140%;
    }

    .contract-feature h2 {
      font-size: 1.2rem;
    }

    .contract-feature h3 {
      font-size: 0.65rem;
    }

    .join-the-communities {
      margin: 30px 0 70px;
    }
  }
`;

export { Root };
